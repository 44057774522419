import React from "react";
import styled from "styled-components";
import NavBar from "../components/Navbar";
import JerinIcon from "../imgs/jerin-icon.png";
import WorkExperiences from "../components/WorkExperience";
import ProjectExperiences from "../components/Projects";

const PageContainer = styled.div`
  margin: 50px 20%;

  @media (max-width: 500px) {
    margin: 30px 10%;
  }
`;

const AboutMe = styled.div`
  display: flex;
  justify-content: flex-end;
  height: auto;
  width: 100%;
  padding: 160px 0 200px 0;

  @media (max-width: 500px) {
    padding: 100px 0 100px 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  text-align: right;
  padding-left: 30%;

  @media (max-width: 500px) {
    padding-left: 10%;
  }
`;

const Bold = styled.text`
  font-weight: bold;
`;

const Colour = styled.text`
  color: #7c985d;
`;

const Italics = styled.text`
  font-style: italic;
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 200px;

  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
`;

const Answers = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  height: auto;

  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 0;
    height: auto;

    > h3 {
      font-size: 1.125rem;
    }
  }
`;

const AnswersContent = styled.div`
  display: flex;
  height: auto;
  padding-right: 50px;

  @media (max-width: 500px) {
    flex-direction: column;
    width: auto;
    height: auto;
    padding-right: 0;

    > h3 {
      font-size: 18px;
    }
  }
`;

const Experience = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 200px 0;

  @media (max-width: 500px) {
    margin: 100px 0;
  }
`;

const ExperienceContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding-top: 50px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const WorkExperience = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 50%;
  padding-right: 150px;

  @media (max-width: 500px) {
    padding-right: 0;
    width: 100%;
  }

  > h4 {
    margin-bottom: 40px !important;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  a:visited {
    text-decoration: none;
    color: #000000;
  }

  > a:hover {
    text-decoration: none;
    color: #000000;
  }
`;

const Projects = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 500px) {
    width: 100%;
  }

  > h4 {
    margin-bottom: 40px !important;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
    color: #000000;
  }

  @media (max-width: 500px) {
    display: flex;
    margin-top: 50px !important;
    flex-direction: column;
    width: 285px;
    height: auto;
    margin-left: 0;
    margin-right: 0;

    > h4 {
      font-size: 18px !important;
    }
  }
`;

const Opportunities = styled.div`
  display: flex;
  width: 55%;
  padding: 150px 0 250px 0;

  @media (max-width: 500px) {
    padding: 100px 0;
    width: 100%;
  }
`;

const Currently = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const JerinImg = styled.img`
  display: flex;
  height: 236px;
  width: 173px;
  margin-right: 150px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

class MainPage extends React.Component {
  render() {
    return (
      <PageContainer>
        <NavBar></NavBar>
        <AboutMe>
          <Content>
            <p>
              hi! i’m <Colour>jerin (she/her)</Colour>, a fourth year computer
              engineering student at the university of waterloo and a perpetual
              learner and hobby enthusiast. i’ve been learning different ways to
              creatively express myself as a developer and taking it one day at
              a time.
              <br />
              <br />i also enjoy working on freelance projects in the form of
              portfolios, digital web apps, or fun, interactive experiences.
              this past year, i've been freelancing at jam3 to build immersive
              and interactive client experiences.
            </p>
          </Content>
        </AboutMe>
        <Questions>
          <h4>
            <Bold>question:</Bold> sum me up in three words?
          </h4>
          <h4>
            <Bold>
              <Colour>answer:</Colour>
            </Bold>{" "}
            creative<sup>(1)</sup>, bookworm<sup>(2)</sup>, and hobby-enthusiast
            <sup>(3)</sup>
          </h4>
          <Answers>
            <AnswersContent>
              <h3 style={{ fontSize: "18px" }}>
                <sup>(1)</sup> studying engineering is fun and challenging, but
                sometimes i need to get some paint on my hands.
              </h3>
            </AnswersContent>
            <AnswersContent>
              <h3 style={{ fontSize: "18px" }}>
                <sup>(2)</sup> i like getting lost in books. i’m currently
                reading <Italics> the monstrumologist </Italics> (again).
              </h3>
            </AnswersContent>
            <AnswersContent>
              <h3 style={{ fontSize: "18px" }}>
                <sup>(3)</sup> i can never sit still in one place so i’m always
                looking for new things to try and do. i’m learning how to
                garden.
              </h3>
            </AnswersContent>
          </Answers>
        </Questions>
        <Currently>
          <div style={{ marginRight: "50px" }}>
            <h2 style={{ paddingBottom: "30px", margin: 0 }}>
              here's what i've been up to <Colour>lately...</Colour>
            </h2>
            <h3 style={{ marginTop: 0 }}>
              <sup>(1)</sup> studying in my final year of computer engineering
              at the university of waterloo
            </h3>
            <h3>
              <sup>(2)</sup> interning as an engineering intern at grailed on
              the sellers squad
            </h3>
            <h3 style={{ marginBottom: 0 }}>
              <sup>(3)</sup> looking for full-time software engineering
              opportunities! <Colour>i’m graduating april 2023!</Colour>
            </h3>
          </div>
          <JerinImg src={JerinIcon} />
        </Currently>
        <Experience>
          <h4>
            here’s a bit of my <Colour>experience</Colour> over the past few
            years…
          </h4>
          <p style={{ fontSize: "18px", marginBottom: 0 }}>
            this year has been all about challenging myself, from joining my
            first design team as lead, developing projects for my dream
            companies, and taking the initiative to help non-profit
            organizations, i’ve focussed on challenging myself to create
            memorable experiences.
          </p>
          <ExperienceContent>
            <WorkExperience>
              <h4>
                my <Colour>experience.</Colour>
              </h4>
              <WorkExperiences
                title=" grailed — engineering intern (incoming)"
                description="(1)"
                label="sept 2022 - dec 2022"
                link="https://www.grailed.com/"
              ></WorkExperiences>
              <WorkExperiences
                title=" 1password — software developer intern"
                description="(2)"
                label="jan 2022 — apr 2022"
                link="https://releases.1password.com/b5x/stable/#settings-for-the-21st-century"
              ></WorkExperiences>
              <WorkExperiences
                title=" jam3 — junior developer (freelance)"
                description="(3)"
                label="aug 2021 — dec 2021"
                link="https://www.jam3.com/"
              ></WorkExperiences>
              <WorkExperiences
                title=" jam3 — developer intern"
                description="(4)"
                label="jan 2021 — may 2021"
                link="https://www.jam3.com/"
              ></WorkExperiences>
              <WorkExperiences
                title=" waterloop — web development lead"
                description="(5)"
                label="jan 2020 — may 2021"
                link="https://teamwaterloop.ca/team"
              ></WorkExperiences>
              <WorkExperiences
                title=" crowdlinker — qa analyst and developer intern"
                description="(6)"
                label="sep 2019 — dec 2019"
                link="https://www.crowdlinker.com/blog/software-qa-internship-crowdlinker"
              ></WorkExperiences>
              <WorkExperiences
                title=" blackberry — web developer intern"
                description="(7)"
                label="jan 2019 — apr 2019"
                link="https://www.blackberry.com/us/en"
              ></WorkExperiences>
            </WorkExperience>
            <Projects>
              <h4>
                my <Colour>projects.</Colour>
              </h4>
              <ProjectExperiences
                title=" sonos roam/beam live site"
                description="an immersive 3-day live event experience curated for sonos’ new speaker launches: roam and beam"
                label="(1)"
                link="https://sonos.press/"
              ></ProjectExperiences>
              <ProjectExperiences
                title=" digicard"
                description="a digital video card for all occasions, bringing people together wherever and whenever"
                label="(2)"
                link="https://digicard.ca/"
              ></ProjectExperiences>
              <ProjectExperiences
                title=" call of duty: warzone micro-site"
                description="a microsite for fans to share and download their own custom role card based on their gameplay"
                label="(3)"
                link="https://rolecall.callofduty.com/"
              ></ProjectExperiences>
              <ProjectExperiences
                title=" copilot ai site"
                description="interactive, informational site created to educate and inform clients about copilots’ ai approach and solutions"
                label="(4)"
                link="https://copilot.ai/"
              ></ProjectExperiences>
              <ProjectExperiences
                title=" waterloop team site"
                description="a rebranded marketing website aimed at educating students and sponsors about uw’s hyperloop design team"
                label="(5)"
                link="https://teamwaterloop.ca/"
              ></ProjectExperiences>
              <ProjectExperiences
                title=" paper piano — htn ’19 winner"
                description="a virtual piano designed to create accessible opportunities
                                 for those wanting to learn musical instruments."
                label="(6)"
                link="https://devpost.com/software/paper-piano-8yzaw9/"
              ></ProjectExperiences>
            </Projects>
          </ExperienceContent>
        </Experience>
        <div style={{ width: "90%" }} id="socials">
          <h2 style={{ paddingBottom: "30px", margin: 0 }}>
            thanks for taking the time to visit my <Colour>small corner</Colour>
            , here are some links below if you want to find out more or reach
            out!
          </h2>
          <h3 style={{ marginTop: 0 }}>
            <sup>(1)</sup>{" "}
            <a
              href="https://twitter.com/jxrinr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Colour>twitter/@jxrinr</Colour>
            </a>
            — a small look into my personality and daily dilemmas
          </h3>
          <h3>
            <sup>(2)</sup>{" "}
            <a
              href="https://github.com/jerinraisa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Colour>github/@jerinraisa</Colour>
            </a>
            — green squares that represent all my code contributions
          </h3>
          <h3 style={{ marginBottom: 0 }}>
            <sup>(3)</sup>{" "}
            <a
              href="https://www.linkedin.com/in/jerin-raisa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Colour>linkedin/@jerin-raisa</Colour>
            </a>
            — an overview of all my experiences navigating through the industry
          </h3>
          <h3 style={{ marginBottom: 0 }}>
            <sup>(4)</sup>{" "}
            <a
              href="https://drive.google.com/file/d/1OjAXdUc_5nmlR6z4wlLj4A4H6z3S5E0c/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Colour>resume</Colour>
            </a>
            — a concise view of my skills, experiences, and projects over the
            years
          </h3>
        </div>
        <Opportunities>
          <h5>
            have any interesting opportunities, book suggestions, or just want
            to chat?
            <a
              href="mailto:jt2999ra@uwaterloo.ca?Subject=Hello%20!"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Colour>
                feel free to hit me up!{" "}
                <span role="img" aria-label="heart emoji">
                  🫶🏼
                </span>
              </Colour>
            </a>{" "}
          </h5>
        </Opportunities>
      </PageContainer>
    );
  }
}

export default MainPage;
