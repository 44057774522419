import React from "react";
import styled from "styled-components";
import { Link, BrowserRouter as Router } from "react-router-dom";
import "./styling/Header.css";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  background-color: none;
  z-index: 1;
  > a:hover {
    transform: none;
    transition: none;
  }

  @media (max-width: 500px) {
    width: 100%;
    > h1 {
      font-size: 0.75em;
    }
  }
`;

const Jerin = styled.div`
  width: auto;
  font-size: 1.125rem;
`;

const PagesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 15em;
  color: black;
  font-weight: 600;

  > a {
    font-family: "Open Sans Pro";
    font-size: 1.125rem;
    font-weight: normal;
    color: #7c985d;
  }
`;

class Navbar extends React.Component {
  render() {
    return (
      <div>
        <HeaderContainer>
          <Router>
            <Link to="/">
              <Jerin>
                <h1>jerin raisa</h1>
              </Jerin>
            </Link>
          </Router>
          <PagesContainer>
            <a href="#socials">socials</a>
          </PagesContainer>
        </HeaderContainer>
      </div>
    );
  }
}

export default Navbar;
