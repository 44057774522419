import React from "react";
import styled from "styled-components";

const IndvProjects = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  margin-bottom: 30px;
`;

const Title = styled.p`
  font-family: "Open Sans Pro";
  font-size: 20px;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
`;

const Description = styled.p`
  font-family: "Open Sans Pro";
  font-size: 18px;
  color: #6b6f76;
  margin-left: 20px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

class Projects extends React.Component {
  render() {
    return (
      <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
        <IndvProjects>
          <Title>
            <sup>{this.props.label}</sup>
            {this.props.title}
          </Title>
          <Description>{this.props.description}</Description>
        </IndvProjects>
      </a>
    );
  }
}

export default Projects;
