import React from "react";
import "./App.css";
import "typeface-source-sans-pro";
import MainPage from "./pages/MainPage";
import "./components/styling/Background.css";

function App() {
  return (
    <div className="Background.css">
      <MainPage />
    </div>
  );
}

export default App;
