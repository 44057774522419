import React from "react";
import styled from "styled-components";

const IndvExperience = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  margin-bottom: 30px;
`;

const Title = styled.p`
  font-family: "Open Sans Pro";
  font-size: 20px;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Date = styled.p`
  font-family: "Open Sans Pro";
  font-size: 18px;
  color: #6b6f76;
  margin-top: 15px;
  margin-left: 20px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

class WorkExperience extends React.Component {
  render() {
    return (
      <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
        <IndvExperience>
          <Title>
            <sup>{this.props.description}</sup>
            {this.props.title}
          </Title>
          <Date>{this.props.label}</Date>
        </IndvExperience>
      </a>
    );
  }
}

export default WorkExperience;
